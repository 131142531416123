import classNames from 'classnames';
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';

import { addToCart, removeFromCart } from '../../core/api/cart';
import { FaIcons } from '../../core/constants';
import { Product } from '../../core/types/product';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import useCartStore from '../../store/shoppingCart';
import FaIcon from '../FaIcon';
import Input from '../Input/Input';
import styles from './styles.module.scss';

type CartAction = {
  savedCount: number;
  actionCount: number;
  discount: number;
};

const PriceSection = ({
  product,
  className,
  view = 'default'
}: {
  product: Product;
  className?: string;
  view?: 'default' | 'full' | 'in-basket';
}) => {
  const { cart } = useCartStore();
  const [productInCartAmount, setProductInCartAmount] = useState<number>(0);
  const [isAddedToCart, setIsAddedToCart] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const cartActionRef = useRef<CartAction>({
    savedCount: 0,
    actionCount: 0,
    discount: 0
  });

  const product_id = product.id;

  const handleCartAction = async () => {
    try {
      setIsProcessing(true);
      const { actionCount, discount } = cartActionRef.current;

      if (actionCount > 0) {
        await addToCart({
          product_id,
          count: actionCount,
          discount,
          delivery_date: '2024-07-10'
        });
      } else if (actionCount < 0) {
        await removeFromCart({
          product_id,
          count: actionCount * -1
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsProcessing(false);
    }
  };

  const runCartAction = useDebouncedCallback(() => handleCartAction(), 500);

  const addItem = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const { actionCount } = cartActionRef.current;
    cartActionRef.current = {
      ...cartActionRef.current,
      actionCount: actionCount + 1
    };
    setProductInCartAmount(productInCartAmount + 1);
    runCartAction();
  };

  const handleAmountInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const { savedCount } = cartActionRef.current;
    const newAmount = Number(e.target.value);
    const actionModifier = newAmount >= savedCount ? 1 : -1;
    const newActionCount = Math.abs(newAmount - savedCount) * actionModifier;

    cartActionRef.current = {
      ...cartActionRef.current,
      actionCount: newActionCount
    };

    setProductInCartAmount(newAmount);
  };

  const removeItem = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const { actionCount } = cartActionRef.current;

    cartActionRef.current = {
      ...cartActionRef.current,
      actionCount: actionCount - 1
    };

    setProductInCartAmount(productInCartAmount - 1);
    runCartAction();
  };

  const deleteAll = async (e: SyntheticEvent) => {
    const { savedCount } = cartActionRef.current;
    e.preventDefault();
    e.stopPropagation();
    setIsProcessing(true);
    try {
      await removeFromCart({
        product_id,
        count: savedCount
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleAmountInputKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === 'Enter') {
      handleCartAction();
    }
  };

  useEffect(() => {
    if (cart) {
      const currentItem = cart?.cartItems?.find(
        ({ product: { id } }) => id === product.id
      );

      setProductInCartAmount(currentItem?.count || 0);
      setIsAddedToCart(!!currentItem?.count);

      cartActionRef.current = {
        savedCount: currentItem?.count || 0,
        actionCount: 0,
        discount: 0
      };
    }
  }, [cart]);

  const disableMinus = productInCartAmount <= 0 || isProcessing;
  const disablePlus = isProcessing;

  return (
    <section
      className={classNames(styles.priceSection, styles[view], className, {
        [styles['is-in-cart']]: isAddedToCart,
        [styles.full]: view === 'in-basket'
      })}
    >
      <div className={styles.wrapper}>
        <p className={styles.productPrice}>
          € {product?.price?.toLocaleString('de-DE') || 0}
        </p>
        <FaIcon faName={FaIcons.truckIcon} className={styles.deliveryIcon} />
        <button
          className={classNames(styles.minus)}
          onClick={removeItem}
          disabled={disableMinus}
        >
          <FaIcon faName={FaIcons.minusIcon} />
        </button>
        <Input
          name="items-count"
          type="number"
          value={productInCartAmount}
          onChange={handleAmountInputChange}
          wrapperClassName={styles.amount}
          inputClassName={styles.amountInput}
          onBlur={handleCartAction}
          disabled={isProcessing}
          onClick={(e) => e.stopPropagation()}
          onKeyDown={handleAmountInputKeyDown}
          min={0}
        />
        <button
          className={classNames(styles.plus)}
          onClick={addItem}
          disabled={disablePlus}
        >
          <FaIcon faName={FaIcons.plusIcon} />
        </button>
        <button
          className={classNames(styles.deleteButton, styles.tableOnly)}
          onClick={deleteAll}
        >
          <FaIcon faName={FaIcons.trashCanIcon} />
        </button>
      </div>
    </section>
  );
};

export default PriceSection;
