import classNames from 'classnames';

import { FilterCategory } from '../../../core/types/category';
import CheckBox from '../../CheckBox/CheckBox';
import styles from './styles.module.scss';

const CategoryItem = ({
  category,
  toggle
}: {
  category: FilterCategory;
  toggle: () => void;
}) => {
  return (
    <li
      className={classNames(styles.categoryItem, {
        [styles.selected]: category.isSelected
      })}
    >
      <CheckBox
        toggle={toggle}
        checked={category.isSelected}
        title={category.name}
      />
    </li>
  );
};

export default CategoryItem;
