import { useTranslation } from 'react-i18next';

import { FaIcons } from '../../../core/constants';
import { Customer } from '../../../core/types/user';
import FaIcon from '../../FaIcon';
import styles from './styles.module.scss';

const CustomerItem = ({
  customer,
  changeActiveCustomer
}: {
  customer: Customer;
  changeActiveCustomer: (customer: Customer) => void;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'multi-user' });
  return (
    <div className={styles.customerItem} key={customer.id}>
      <p className={styles.customerText}>
        <span className={styles.customerId}>{customer.id} — </span>
        <span className={styles.customerName}>{customer.name}</span>
      </p>
      <button
        className={styles.customerButton}
        onClick={() => changeActiveCustomer(customer)}
      >
        <span className={styles.customerButtonText}>
          {t('change-customer-button')}
        </span>
        <FaIcon
          faName={FaIcons.chevronDown}
          className={styles.customerButtonIcon}
        />
      </button>
    </div>
  );
};

export default CustomerItem;
