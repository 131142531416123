import i18n from '../../i18n.config';
import { ROUTES, STORAGE_KEYS } from '../constants';

const API_URL = process.env.REACT_APP_API_URL;

const getAuthorizeToken = () => localStorage.getItem(STORAGE_KEYS.auth_token);

export type ApiClient = {
  headers: Record<string, string>;
  authorize: boolean;
  withoutAuth(): ApiClient;
  request<ResponseType = Response>(
    url: string,
    method: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH',
    params?: RequestInit
  ): Promise<{ statusCode: number; response: ResponseType }>;
  get<ResponseType = Response>(
    url: string,
    params?: RequestInit
  ): Promise<{ statusCode: number; response: ResponseType }>;
  post<ResponseType = Response>(
    url: string,
    params?: RequestInit
  ): Promise<{ statusCode: number; response: ResponseType }>;
  put<ResponseType = Response>(
    url: string,
    params?: RequestInit
  ): Promise<{ statusCode: number; response: ResponseType }>;
  patch<ResponseType = Response>(
    url: string,
    params?: RequestInit
  ): Promise<{ statusCode: number; response: ResponseType }>;
  delete<ResponseType = Response>(
    url: string,
    params?: RequestInit
  ): Promise<{ statusCode: number; response: ResponseType }>;
};

function getUrlWithBase(url: string) {
  return `${API_URL}/${url}`;
}

const defaultHeaders = {
  Accept: 'application/json, application/ld+json',
  'Content-Type': 'application/json'
};

const apiClient: ApiClient = {
  headers: { ...defaultHeaders },
  authorize: true,

  withoutAuth() {
    const client = Object.create(this);
    client.authorize = false;
    return client;
  },

  async request<ResponseType = Response>(
    url: string,
    method: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH',
    params?: RequestInit
  ) {
    const headers = { ...this.headers };
    if (params?.body instanceof FormData) {
      delete headers['Content-Type'];
    }

    if (i18n) {
      headers['Accept-Language'] = i18n.language;
    }

    if (this.authorize) {
      const authorizeToken = getAuthorizeToken();
      if (authorizeToken) {
        headers.Authorization = `Bearer ${authorizeToken}`;
      }
    }

    const response = await fetch(getUrlWithBase(url), {
      method,
      ...params,
      headers: {
        ...headers,
        ...params?.headers
      }
    });
    if (response.status === 401) {
      (window as any).navigation.navigate(ROUTES.login);
      localStorage.removeItem(STORAGE_KEYS.auth_token);
    }
    const isJson = response.headers.get('content-type')?.includes('json');

    return {
      statusCode: response.status,
      response: (isJson ? await response.json() : response) as ResponseType
    };
  },

  async get<ResponseType = Response>(url: string, params?: RequestInit) {
    return this.request<ResponseType>(url, 'GET', params);
  },
  async delete<ResponseType = Response>(url: string, params?: RequestInit) {
    return this.request<ResponseType>(url, 'DELETE', params);
  },
  async post<ResponseType = Response>(url: string, params?: RequestInit) {
    return this.request<ResponseType>(url, 'POST', params);
  },
  async put<ResponseType = Response>(url: string, params?: RequestInit) {
    return this.request<ResponseType>(url, 'PUT', params);
  },
  async patch<ResponseType = Response>(url: string, params?: RequestInit) {
    return this.request<ResponseType>(url, 'PATCH', params);
  }
};

export default apiClient;
