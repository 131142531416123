import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { fetchMyCustomers, setActiveCustomer } from '../../../core/api/user';
import { FaIcons } from '../../../core/constants';
import { Customer } from '../../../core/types/user';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
import useUserStore from '../../../store/user';
import Input from '../../Input/Input';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import CustomerItem from './CustomerItem';
import styles from './styles.module.scss';

const MultiUserModal = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'multi-user' });
  const { userCustomers } = useUserStore();
  const [searchValue, setSearchValue] = useState<string>('');

  const startSearch = useDebouncedCallback(
    (value: string) => fetchMyCustomers(value),
    500
  );

  const handleChangeSearch = (value: string) => {
    setSearchValue(value);
    startSearch(value);
  };

  const changeActiveCustomer = async (customer: Customer) => {
    try {
      await setActiveCustomer(customer);

      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ModalWrapper onClose={onClose}>
      <div className={styles.multiUserWrapper}>
        <div className={styles.multiUser}>
          <div className={styles.description}>
            <span className={styles.title}>{t('title')}</span>
            <span className={styles.text}>{t('sub-title')}</span>
          </div>
          <Input
            name="search"
            inputClassName={styles.userSearch}
            placeholder={t('search-placeholder')}
            value={searchValue}
            onChange={(e) => handleChangeSearch(e.target.value)}
            Icon={FaIcons.barsFilterIcon}
            iconClassName={styles.filterIcon}
            wrapperClassName={styles.searchWrapper}
          />
          <div className={styles.customers}>
            {userCustomers.map((customer) => (
              <CustomerItem
                customer={customer}
                key={customer.id}
                changeActiveCustomer={changeActiveCustomer}
              />
            ))}
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default MultiUserModal;
