import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const storeLng = localStorage.getItem('i18nextLng');
const activeLng = storeLng || 'en';

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .use(I18nextBrowserLanguageDetector)
  .init({
    ns: ['translation', 'validation'],
    defaultNS: 'translation',
    lng: activeLng,
    detection: {
      caches: ['localStorage']
    },
    fallbackLng: 'en'
  });

export default i18n;
