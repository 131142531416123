import { FaIcons } from '../../core/constants';
import FaIcon from '../FaIcon';
import styles from './styles.module.scss';

const ErrorMessage = ({ message }: { message: string }) =>
  message ? (
    <p className={styles.errorMessage}>
      <FaIcon faName={FaIcons.xMark} className={styles.errorIcon} />
      {message}
    </p>
  ) : (
    <></>
  );

export default ErrorMessage;
