import classNames from 'classnames';

import { FaIcons, LIST_VIEWS } from '../../core/constants';
import useListViewStore from '../../store/listView';
import FaIcon from '../FaIcon';
import styles from './styles.module.scss';

const ViewToggle = () => {
  const { productsListView, changeProductsListView } = useListViewStore(
    (state) => state
  );

  return (
    <div className={styles.viewToggle}>
      <button
        className={classNames(styles.toggleButton, {
          [styles.selected]: productsListView === LIST_VIEWS.grid
        })}
        onClick={() => changeProductsListView(LIST_VIEWS.grid)}
      >
        <FaIcon faName={FaIcons.gridIcon} />
      </button>
      <button
        className={classNames(styles.toggleButton, {
          [styles.selected]: productsListView === LIST_VIEWS.table
        })}
        onClick={() => changeProductsListView(LIST_VIEWS.table)}
      >
        <FaIcon faName={FaIcons.list} />
      </button>
    </div>
  );
};

export default ViewToggle;
