import classNames from 'classnames';

import styles from './styles.module.scss';

const Counter = ({
  count,
  view,
  size = 'medium',
  className = ''
}: {
  count: number;
  view: 'red' | 'green';
  size?: 'medium' | 'large';
  className?: string;
}) => (
  <div
    className={classNames(
      styles.counter,
      className,
      styles[view],
      styles[size]
    )}
  >
    {count}
  </div>
);

export default Counter;
