import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import AuthWrapper from './components/AuthWrapper';
import AssortmentPage from './pages/assortment';
import ForgetPasswordPage from './pages/forget-password';
import InvoicesPage from './pages/invoices';
import ItemsOnOrderPage from './pages/items-on-order';
import ItemsReturnPage from './pages/items-return';
import LoginPage from './pages/login';
import NotificationsPage from './pages/notifications';
import OrderPage from './pages/order';
import RequestedReturnsPage from './pages/outstanding-returns';
import PurchaseHistoryPage from './pages/purchase-history';
import ResetPasswordPage from './pages/reset-password';
import ShoppingBasketPage from './pages/shopping-basket';

function App() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/login" Component={LoginPage} />
        <Route path="/forget-password" Component={ForgetPasswordPage} />
        <Route path="/reset-password/:token" Component={ResetPasswordPage} />
        <Route
          path="/assortment"
          Component={() => (
            <AuthWrapper>
              <AssortmentPage />
            </AuthWrapper>
          )}
        />
        <Route
          path="/shopping-basket"
          Component={() => (
            <AuthWrapper>
              <ShoppingBasketPage />
            </AuthWrapper>
          )}
        />
        <Route
          path="/order/:id"
          Component={() => (
            <AuthWrapper>
              <OrderPage />
            </AuthWrapper>
          )}
        />
        <Route
          path="/items-return"
          Component={() => (
            <AuthWrapper>
              <ItemsReturnPage />
            </AuthWrapper>
          )}
        />
        <Route
          path="/outstanding-returns"
          Component={() => (
            <AuthWrapper>
              <RequestedReturnsPage />
            </AuthWrapper>
          )}
        />
        <Route
          path="/invoices"
          Component={() => (
            <AuthWrapper>
              <InvoicesPage />
            </AuthWrapper>
          )}
        />
        <Route
          path="/purchase-history"
          Component={() => (
            <AuthWrapper>
              <PurchaseHistoryPage />
            </AuthWrapper>
          )}
        />
        <Route
          path="/items-on-order"
          Component={() => (
            <AuthWrapper>
              <ItemsOnOrderPage />
            </AuthWrapper>
          )}
        />
        <Route
          path="/notifications"
          Component={() => (
            <AuthWrapper>
              <NotificationsPage />
            </AuthWrapper>
          )}
        />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
