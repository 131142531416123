import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FaIcons } from '../../core/constants';
import DateSelector from '../DateSelector/DateSelector';
import DropdownBlock from '../DropdownBlock/DropdownBlock';

const DeliveryDateSelect = ({ view }: { view: 'light' | 'lg-transparent' }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'menu' });
  const [deliveryDate, setDeliveryDate] = useState<Date>(new Date());
  const displayedDeliveryDate = moment(deliveryDate).format('dddd DD/MM/yyyy');

  const handleDeliveryDateChange = (date: Date) => {
    setDeliveryDate(date);
  };
  return (
    <DropdownBlock
      view={view}
      title={t('delivery-title')}
      value={`${displayedDeliveryDate}`}
      iconName={FaIcons.calendar}
    >
      <DateSelector
        handleChange={handleDeliveryDateChange}
        date={deliveryDate}
      />
    </DropdownBlock>
  );
};

export default DeliveryDateSelect;
