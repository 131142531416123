import classNames from 'classnames';
import { ReactNode } from 'react';

import styles from './Button.module.scss';

const Button = ({
  type = 'button',
  view = 'primary',
  onClick,
  buttonText,
  className = '',
  disabled = false,
  children
}: {
  type?: 'button' | 'submit';
  view?: 'primary' | 'secondary' | 'dark' | 'transparent' | 'green';
  onClick?: () => void;
  buttonText?: string | number;
  className?: string;
  disabled?: boolean;
  children?: ReactNode;
}) => {
  return (
    <button
      className={classNames(styles.button, className, styles[view])}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {buttonText || children}
    </button>
  );
};

export default Button;
