import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FaIcons } from '../../../core/constants';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
import useProductsStore from '../../../store/products';
import Input from '../../Input/Input';
import styles from './styles.module.scss';

const ItemsSearch = ({ className }: { className?: string }) => {
  const {
    changeFilters,
    filters: { search }
  } = useProductsStore();
  const { t } = useTranslation('translation');
  const [searchValue, setSearchValue] = useState<string>('');

  const startSearch = useDebouncedCallback(
    (value: string) => changeFilters({ search: value }),
    500
  );

  useEffect(() => {
    if (searchValue || search) startSearch(searchValue);
  }, [searchValue]);

  return (
    <div className={classNames(styles.itemsSearch, className)}>
      <Input
        name="item search"
        placeholder={t('filters.item-search-placeholder')}
        onChange={(e) => setSearchValue(e.target.value)}
        value={searchValue}
        Icon={FaIcons.searchIcon}
        iconClassName={styles.searchIcon}
        inputClassName={styles.searchInput}
      />
    </div>
  );
};

export default ItemsSearch;
