import i18n from '../../i18n.config';
import { ERRORS, OrderStatus } from '../constants';
import { Order } from '../types/product';
import apiClient from './client';

export const getOrder = async (id: number): Promise<Order | Error> => {
  const { statusCode, response } = await apiClient.get<Order>(
    `api/orders/${id}`
  );

  if (statusCode === 200) {
    return response;
  } else {
    throw new Error(i18n.t(ERRORS.default));
  }
};

export const getOrders = async (
  status: OrderStatus
): Promise<Order[] | Error> => {
  const query = `?status=${status}`;
  const { statusCode, response } = await apiClient.get<Order[]>(
    `api/orders${query}`
  );

  if (statusCode === 200) {
    return response;
  } else {
    throw new Error(i18n.t(ERRORS.default));
  }
};
