import classNames from 'classnames';
import { useState } from 'react';

import { FaIcons } from '../../core/constants';
import FaIcon from '../FaIcon';
import SortDropdown from '../ProductsList/SortDropdown';
import ArticlesDropdown from './ArticlesDropdown/ArticlesDropdown';
import CategoryFilter from './CategoryFilter/CategoryFilter';
import ItemsSearch from './ItemsSearch/ItemsSearch';
import styles from './styles.module.scss';

const FiltersSideBar = () => {
  const [showSideBar, setShowSideBar] = useState<boolean>(false);

  return (
    <div className={styles.filtersSideBarWrapper}>
      <button
        className={styles.filtersButton}
        onClick={() => setShowSideBar(true)}
      >
        <FaIcon faName={FaIcons.filterListIcon} />
      </button>
      <div
        className={classNames(styles.filtersSideBar, {
          [styles.open]: showSideBar
        })}
      >
        <button
          className={styles.filtersCloseButton}
          onClick={() => setShowSideBar(false)}
        >
          <FaIcon faName={FaIcons.xMark} />
        </button>
        <ItemsSearch className={styles.desktopOnly} />
        <ArticlesDropdown />
        <SortDropdown
          className={classNames(styles.filterBarSort, styles.mobileOnly)}
        />
        <CategoryFilter />
      </div>
    </div>
  );
};

export default FiltersSideBar;
