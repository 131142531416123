import classNames from 'classnames';

import { FaIcons } from '../../core/constants';
import FaIcon from '../FaIcon';
import styles from './styles.module.scss';

const DownloadOverlay = ({
  link,
  view = 'dark'
}: {
  link: string;
  view?: 'dark' | 'light';
}) => (
  <a
    download={link}
    href={link}
    className={classNames(styles.downloadOverlay, styles[view])}
  >
    <FaIcon faName={FaIcons.circleArrowDownIcon} />
    download
  </a>
);

export default DownloadOverlay;
