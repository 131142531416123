import i18n from '../../i18n.config';
import useProductsStore from '../../store/products';
import { ERRORS, ReturnRequestStatus, SortOptions } from '../constants';
import {
  Product,
  ProductPromotion,
  RequestedReturn,
  ReturnRequestItem
} from '../types/product';
import apiClient from './client';

const SortOrders = {
  [SortOptions.productsInStock]: '',
  [SortOptions.bestProducts]: ''
};

export const getProducts = async (): Promise<Product[] | Error> => {
  const {
    pageLimit,
    currentPage,
    totalCount,
    changeTotalCount,
    filters,
    sorting
  } = useProductsStore.getState();
  const searchKey = isNaN(Number(filters?.search))
    ? `translations.name`
    : 'articleNr';
  const searchQuery = filters.search ? `&${searchKey}=${filters.search}` : '';
  const categoriesQuery = filters.categories.length
    ? `&categories.id=${filters.categories}`
    : '';

  const { statusCode, response } = await apiClient.get<Product[]>(
    `api/products?page=${currentPage}&itemsPerPage=${pageLimit}${searchQuery}${categoriesQuery}${SortOrders[sorting]}`
  );

  if (statusCode === 200) {
    const products = response;

    //Temporary
    if (response.length === pageLimit) {
      changeTotalCount(totalCount + pageLimit + 1);
    } else {
      changeTotalCount(0);
    }

    return products;
  } else throw new Error(i18n.t(ERRORS.default));
};

export const getPromotions = async (): Promise<ProductPromotion[] | Error> => {
  const { statusCode, response } =
    await apiClient.get<ProductPromotion[]>('api/promotions');

  if (statusCode === 200) return response;
  else throw new Error(i18n.t(ERRORS.default));
};

type CustomerSheetRequest = {
  search: string;
};

export const getCustomerProducts = async ({
  search
}: CustomerSheetRequest): Promise<Product[] | Error> => {
  const searchKey = isNaN(Number(search)) ? `translations.name` : 'articleNr';
  const query = search ? `?${searchKey}=${search}` : '';

  const { statusCode, response } = await apiClient.get<Product[]>(
    `api/products/customer-sheet${query}`
  );

  if (statusCode === 200) return response;
  else throw new Error(i18n.t(ERRORS.default));
};

export const requestReturnItems = async ({
  itemsForReturn
}: {
  itemsForReturn: ReturnRequestItem[];
}): Promise<void | Error> => {
  const { statusCode } = await apiClient.post('api/returns/submit', {
    body: JSON.stringify(itemsForReturn)
  });

  if (statusCode === 201) return;
  else throw new Error(i18n.t(ERRORS.default));
};

export const getRequestedReturns = async (
  status: ReturnRequestStatus
): Promise<RequestedReturn[] | Error> => {
  const query = `?status=${status}`;
  const { statusCode, response } = await apiClient.get<RequestedReturn[]>(
    `api/returns${query}`
  );

  if (statusCode === 200) {
    return response;
  } else {
    throw new Error(i18n.t(ERRORS.default));
  }
};
