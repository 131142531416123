import { FaIcons } from '../../core/constants';
import FaIcon from '../FaIcon';
import styles from './styles.module.scss';

const EmptyState = ({ text }: { text: string }) => (
  <section className={styles.emptyState}>
    <FaIcon faName={FaIcons.messageSmileIcon} className={styles.icon} />
    <p className={styles.text}>{text}</p>
  </section>
);

export default EmptyState;
