import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../components/Button/Button';
import MainLayout from '../../components/MainLayout/MainLayout';
import NotificationCard from '../../components/NotificationCard/NotificationCard';
import {
  getNotifications,
  markAsRead,
  removeNotifications
} from '../../core/api/notifications';
import { Notification, NotificationItem } from '../../core/types/user';
import styles from './styles.module.scss';

const NotificationsPage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'notifications' });
  const [notifications, setNotifications] = useState<NotificationItem[]>([]);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleMarkAsRead = async (markAll?: boolean) => {
    try {
      const ids = markAll ? notifications.map(({ id }) => id) : selectedIds;

      await markAsRead(ids);

      const updatedNotifications = notifications.reduce(
        (result, notification) => {
          if (ids.includes(notification.id))
            return [
              ...result,
              {
                ...notification,
                isRead: true,
                isSelected: false
              }
            ];

          return [...result, notification];
        },
        [] as NotificationItem[]
      );

      setNotifications(updatedNotifications);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemove = async (ids: number[]) => {
    try {
      await removeNotifications(ids);

      const newIds = selectedIds.filter(
        (selectedId) => !ids.includes(selectedId)
      );
      const newNotifications = notifications.filter(
        ({ id: currentId }) => !ids.includes(currentId)
      );

      setSelectedIds(newIds);
      setNotifications(newNotifications);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleSelection = (notification: NotificationItem) => {
    if (notification.isSelected) {
      const newIds = selectedIds.filter((id) => id !== notification.id);
      setSelectedIds(newIds);
    } else {
      setSelectedIds([...selectedIds, notification.id]);
    }

    const notificationIndex = notifications.findIndex(
      ({ id }) => id === notification.id
    );

    const newNotifications = [...notifications];
    newNotifications[notificationIndex].isSelected =
      !newNotifications[notificationIndex].isSelected;

    setNotifications(newNotifications);
  };

  const fetchNotifications = async () => {
    try {
      setIsLoading(true);

      const pageToFetch = currentPage + 1;
      setCurrentPage(pageToFetch);

      const fetchedNotifications = await getNotifications(pageToFetch);
      const formattedNotifications = (
        fetchedNotifications as Notification[]
      ).map((notification: Notification) => ({
        ...notification,
        isSelected: false
      }));

      setNotifications([...notifications, ...formattedNotifications]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const updateDate = moment().format('DD-MM/YYYY');

  return (
    <MainLayout>
      <section className={styles.notificationsPage}>
        <header className={styles.notificationsHeader}>
          <h1 className={styles.title}>{t('title')}</h1>
          <p className={styles.lastUpdate}>
            {t('last-updated')} {updateDate}
          </p>
          <div className={styles.headerButtons}>
            <Button
              view="transparent"
              className={styles.headerButton}
              buttonText={t('mark-selected-as-read')}
              onClick={() => handleMarkAsRead()}
            />
            <Button
              view="transparent"
              className={styles.headerButton}
              buttonText={t('remove-selected')}
              onClick={() => handleRemove(selectedIds)}
            />
            <Button
              view="transparent"
              className={styles.headerButton}
              buttonText={t('mark-all-as-read')}
              onClick={() => handleMarkAsRead(true)}
            />
          </div>
        </header>
        <main className={styles.notificationsMain}>
          <div className={styles.notificationsList}>
            {!!notifications.length &&
              notifications.map((notification) => (
                <NotificationCard
                  key={notification.id}
                  notification={notification}
                  toggleSelect={() => toggleSelection(notification)}
                  handleRemove={() => handleRemove([notification.id])}
                />
              ))}
          </div>
          <Button
            view="secondary"
            className={styles.loadMore}
            buttonText={t('load-more')}
            onClick={() => null}
            disabled={isLoading}
          />
        </main>
      </section>
    </MainLayout>
  );
};

export default NotificationsPage;
