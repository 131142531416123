import { ReactNode } from 'react';

import LanguageToggle from '../LanguageToggle/LanguageToggle';
import styles from './styles.module.scss';

const AuthLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div className={styles.authLayout}>
      <div className={styles.authLayoutContent}>
        <div className={styles.authFormWrapper}>
          <div className={styles.headSection}>
            <img
              src="/images/logo-bleijenberg.svg"
              alt="project logo"
              className={styles.logo}
            />
            <LanguageToggle />
          </div>
          {children}
        </div>
        <div className={styles.contactsWrapper}>
          <p className={styles.companyName}>Bleijenberg nv</p>
          <p className={styles.companyAddress}>Ter Stratenweg 46, 2520 Ranst</p>
          <p className={styles.contacts}>
            Tel 03/326.36.00 —{' '}
            <a href="/" className={styles.contactsEmail}>
              sales@bleijenberg.be
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
