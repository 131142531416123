import { create } from 'zustand';

import { LIST_VIEWS } from '../core/constants';

interface ListViewStore {
  productsListView: LIST_VIEWS;
  changeProductsListView: (newView: LIST_VIEWS) => void;
}

const useListViewStore = create<ListViewStore>((set) => ({
  productsListView: LIST_VIEWS.grid,
  changeProductsListView: (newValue: LIST_VIEWS) =>
    set((state) => ({ ...state, productsListView: newValue }))
}));

export default useListViewStore;
