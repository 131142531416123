import classNames from 'classnames';

import { FaIcons } from '../core/constants';

interface FaIconProps {
  faName: FaIcons;
  onClick?: () => void;
  className?: string;
}

const FaIcon = ({ faName, onClick, className }: FaIconProps) => {
  return (
    <i
      onClick={() => onClick?.()}
      className={classNames(className, faName)}
    ></i>
  );
};

export default FaIcon;
