import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';

import AuthLayout from '../../components/AuthLayout/AuthLayout';
import Button from '../../components/Button/Button';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import Input from '../../components/Input/Input';
import ValidationErrorMessage from '../../components/ValidationErrorMessage/ValidationErrorMessage';
import { checkResetTokenValidity, setNewPassword } from '../../core/api/user';
import { FaIcons, ROUTES } from '../../core/constants';
import styles from './styles.module.scss';

type ResetValues = {
  newPassword: string;
  repeatPassword: string;
};

const RESET_PASSWORD_INIT_VALUES: ResetValues = {
  newPassword: '',
  repeatPassword: ''
};

const ResetPasswordPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'reset-password' });
  const { t: vT } = useTranslation('validation');
  const [resetError, setResetError] = useState<string>('');
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required(vT('required', { field: vT('newPassword') }))
      .matches(
        /^(?=.*\d)(?=.*[!@#$%^&*.])(?=.*[A-Z]).{8,}$/,
        vT('password-requirements')
      ),
    repeatPassword: Yup.string()
      .required(vT('required', { field: vT('repeatPassword') }))
      .when('newPassword', (newPassword, schema) =>
        schema.equals(newPassword, vT('not-matching-passwords'))
      )
  });

  const validateToken = async () => {
    try {
      await checkResetTokenValidity(token as string);
    } catch (error: any) {
      console.error('validate token error', error);
      navigate(ROUTES.forgetPassword);
    }
  };

  const handleSubmit = async (values: ResetValues) => {
    try {
      console.log(values);
      await setNewPassword({
        password: values.newPassword,
        token: token as string
      });
      navigate(ROUTES.login);
    } catch (error: any) {
      setResetError(error.message);
    }
  };

  useEffect(() => {
    validateToken();
  }, []);

  const newPasswordInputType = showNewPassword ? 'text' : 'password';
  const repeatPasswordInputType = showRepeatPassword ? 'text' : 'password';

  const newPasswordIcon = showNewPassword
    ? FaIcons.eyeIcon
    : FaIcons.slashEyeIcon;
  const repeatPasswordIcon = showRepeatPassword
    ? FaIcons.eyeIcon
    : FaIcons.slashEyeIcon;

  return (
    <AuthLayout>
      <Formik
        onSubmit={handleSubmit}
        initialValues={RESET_PASSWORD_INIT_VALUES}
        validationSchema={validationSchema}
        validateOnChange={false}
      >
        {({ values, handleChange, errors }) => {
          const errorMessage = errors.newPassword || errors.repeatPassword;
          return (
            <Form className={styles.resetPasswordForm}>
              <h2 className={styles.formTitle}>{t('title')}</h2>
              <ErrorMessage message={resetError} />
              <ValidationErrorMessage message={errorMessage} />
              <div className={styles.formFields}>
                <Input
                  name="newPassword"
                  type={newPasswordInputType}
                  label={t('new-password-input-label')}
                  value={values.newPassword}
                  onChange={handleChange}
                  Icon={newPasswordIcon}
                  iconAction={() => setShowNewPassword(!showNewPassword)}
                />
                <Input
                  name="repeatPassword"
                  type={repeatPasswordInputType}
                  label={t('repeat-password-input-label')}
                  value={values.repeatPassword}
                  onChange={handleChange}
                  Icon={repeatPasswordIcon}
                  iconAction={() => setShowRepeatPassword(!showRepeatPassword)}
                />
              </div>
              <Button
                view="primary"
                buttonText={t('submit-btn-title')}
                type="submit"
              />
            </Form>
          );
        }}
      </Formik>
    </AuthLayout>
  );
};

export default ResetPasswordPage;
