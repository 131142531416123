import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  ArticlesItems,
  ASSORTMENT_ARTICLES,
  FaIcons
} from '../../../core/constants';
import useProductsStore from '../../../store/products';
import FaIcon from '../../FaIcon';
import styles from './styles.module.scss';

const ArticlesIcons = {
  [ArticlesItems.fullRange]: FaIcons.list,
  [ArticlesItems.promotions]: FaIcons.percentIcon,
  [ArticlesItems.favorite]: FaIcons.filledHeartIcon,
  [ArticlesItems.quickSale]: FaIcons.bellIcon,
  [ArticlesItems.myOrderList]: FaIcons.filledStarIcon
};

const ArticlesDropdown = () => {
  const { t } = useTranslation('translation');
  const {
    filters: { article },
    changeFilters
  } = useProductsStore();

  return (
    <div className={styles.articlesSelect}>
      {ASSORTMENT_ARTICLES.map(({ value, title }) => (
        <p
          className={classNames(styles.articleItem, {
            [styles.selected]: article.value === value
          })}
          key={value}
          onClick={() => changeFilters({ article: { value, title } })}
        >
          <FaIcon faName={ArticlesIcons[value]} />
          {t(title)}
        </p>
      ))}
    </div>
  );
};

export default ArticlesDropdown;
