import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FaIcons, SortOptions } from '../../core/constants';
import useClickOutside from '../../hooks/useClickOutside';
import useProductsStore from '../../store/products';
import FaIcon from '../FaIcon';
import styles from './styles.module.scss';

const SORT_OPTIONS = [
  {
    label: 'sort.best-products',
    value: SortOptions.bestProducts
  },
  {
    label: 'sort.products-in-stock',
    value: SortOptions.productsInStock
  }
];

const SortDropdown = ({ className }: { className?: string }) => {
  const { t } = useTranslation('translation');
  const { changeSorting, sorting } = useProductsStore();
  const [showValues, setShowValues] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<{
    label: string;
    value: SortOptions;
  }>(SORT_OPTIONS.find(({ value }) => sorting === value) || SORT_OPTIONS[0]);
  const wrapperRef = useClickOutside(() => setShowValues(false));

  return (
    <div
      className={classNames(styles.sortDropdown, className)}
      ref={wrapperRef}
    >
      <p className={styles.sortHead} onClick={() => setShowValues(!showValues)}>
        {t('sort.title')}{' '}
        <b>
          {t(selectedValue.label)} <FaIcon faName={FaIcons.chevronDown} />{' '}
        </b>
      </p>
      {showValues && (
        <div className={styles.sortValues}>
          {SORT_OPTIONS.map((option) => (
            <button
              key={option.label}
              className={styles.sortOption}
              onClick={() => {
                setSelectedValue(option);
                changeSorting(option.value);
                setShowValues(false);
              }}
            >
              {t(option.label)}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default SortDropdown;
