import 'react-multi-carousel/lib/styles.css';

import { t } from 'i18next';
import { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';

import { getPromotions } from '../../core/api/products';
import { Product, ProductPromotion } from '../../core/types/product';
import ProductCard from '../ProductCard/ProductCard';
import CarouselArrow from './CarouselArrow';
import styles from './styles.module.scss';

const PromotedProductCarousel = () => {
  const [products, setProducts] = useState<Product[]>([]);

  const fetchPromotions = async () => {
    try {
      const promotions = await getPromotions();
      setProducts(
        (promotions as ProductPromotion[]).map(({ product, promotion }) => ({
          ...product,
          promotion
        }))
      );
    } catch (error) {
      console.error(error);
    }
  };

  const ButtonGroup = ({
    next,
    previous
  }: {
    next?: () => void;
    previous?: () => void;
  }) => {
    return (
      <div className={styles.carouselButtonGroup}>
        <CarouselArrow
          direction="left"
          onClick={() => {
            if (previous) {
              previous();
            }
          }}
        />
        <CarouselArrow
          direction="right"
          onClick={() => {
            if (next) {
              next();
            }
          }}
        />
      </div>
    );
  };

  useEffect(() => {
    fetchPromotions();
  }, []);

  if (!products.length) return <></>;

  return (
    <div className={styles.carousel}>
      <div className={styles.carouselTitle}>{t('promotions')}</div>
      <Carousel
        slidesToSlide={1}
        swipeable
        containerClass={styles.carouselContainer}
        sliderClass={styles.carouselSlider}
        showDots={false}
        arrows={false}
        draggable
        infinite
        renderButtonGroupOutside
        customButtonGroup={<ButtonGroup />}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 768
            },
            items: 3
          },
          tabletPortrait: {
            breakpoint: {
              max: 960,
              min: 601
            },
            items: 2
          },
          phone: {
            breakpoint: {
              max: 600,
              min: 0
            },
            items: 1
          }
        }}
      >
        {products.map((product) => (
          <ProductCard key={product.id} product={product} view="promoted" />
        ))}
      </Carousel>
    </div>
  );
};

export default PromotedProductCarousel;
