import { Category, FilterCategory } from '../../../core/types/category';
import CategoryItem from './CategoryItem';
import styles from './styles.module.scss';

const CategoriesList = ({
  parentCategory,
  subCategories,
  toggleCategory
}: {
  parentCategory?: Category;
  subCategories: FilterCategory[];
  toggleCategory: (category: FilterCategory) => void;
}) => {
  return (
    <div className={styles.categoriesListWrapper}>
      {!!parentCategory && (
        <p className={styles.listTitle}>{parentCategory.name}</p>
      )}
      <ul className={styles.categoriesList}>
        {subCategories.map((category) => (
          <CategoryItem
            key={category.code}
            category={category}
            toggle={() => toggleCategory(category)}
          />
        ))}
      </ul>
    </div>
  );
};

export default CategoriesList;
