import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FaIcons } from '../../core/constants';
import { RequestedReturnItem } from '../../core/types/product';
import Button from '../Button/Button';
import FaIcon from '../FaIcon';
import ActionConfirmation from '../Modals/ActionConfirmation/ActionConfirmation';
import OrderTable from '../OrderTable/OrderTable';
import styles from './styles.module.scss';

const OutstandingReturnTable = ({
  items,
  returnDate
}: {
  items: RequestedReturnItem[];
  returnDate: string;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'items-return' });
  const [tableItems, setTableItems] = useState<RequestedReturnItem[]>(items);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const rowToRemoveRef = useRef<string>('');

  const requestRemove = (articleNr: string) => {
    setShowConfirmationModal(true);
    rowToRemoveRef.current = articleNr;
  };

  const handleItemRemove = () => {
    const articleNr = rowToRemoveRef.current;
    const newItems = tableItems.filter(
      ({ productArticleNumber }) => productArticleNumber !== articleNr
    );

    setTableItems(newItems);
  };

  const columns = [
    {
      name: t('table.article-number'),
      selector: (row: RequestedReturnItem) => row.productArticleNumber,
      grow: 0.5
    },
    {
      name: t('table.description'),
      selector: (row: RequestedReturnItem) => row.description,
      grow: 3
    },
    {
      name: t('table.quantity'),
      cell: (row: RequestedReturnItem) => row.quantity
    },
    {
      name: t('table.weight'),
      cell: (row: RequestedReturnItem) => row.weight
    },
    {
      name: t('table.lot-number'),
      cell: (row: RequestedReturnItem) => row.lotNumber
    },
    {
      name: t('table.best-before'),
      cell: (row: RequestedReturnItem) => row.bestBefore
    },
    {
      name: t('table.return-reason'),
      cell: (row: RequestedReturnItem) => row.returnReason,
      grow: 3
    },
    {
      name: t('table.image'),
      cell: (row: RequestedReturnItem) => (
        <img
          className={styles.rowImage}
          src={row.imagePath || 'images/img-placeholder.svg'}
        />
      )
    },
    {
      name: '',
      cell: (row: RequestedReturnItem) => (
        <Button
          view="transparent"
          className={styles.removeButton}
          onClick={() => requestRemove(row.productArticleNumber)}
        >
          <FaIcon faName={FaIcons.trashCanIcon} />
        </Button>
      ),
      grow: 0.5
    }
  ];
  return (
    <>
      <OrderTable
        titleView="orange"
        titleMainText={returnDate}
        tableProps={{ columns, data: tableItems }}
      />
      {showConfirmationModal && (
        <ActionConfirmation
          confirmationText={t('remove-confirmation-text')}
          confirmText={t('confirmation-yes')}
          cancelText={t('confirmation-no')}
          onClose={() => setShowConfirmationModal(false)}
          handleCancel={() => setShowConfirmationModal(false)}
          handleConfirm={handleItemRemove}
        />
      )}
    </>
  );
};

export default OutstandingReturnTable;
