import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getActiveCart } from '../../core/api/cart';
import {
  fetchActiveCustomer,
  fetchMyCustomers,
  getCurrentUser
} from '../../core/api/user';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import styles from './styles.module.scss';

const MainLayout = ({ children }: { children: ReactNode }) => {
  const { i18n } = useTranslation('translation');
  const fetchGeneralData = async () => {
    try {
      await fetchMyCustomers();
      await fetchActiveCustomer();
      await getActiveCart();
      await getCurrentUser();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchGeneralData();
  }, [i18n.language]);

  return (
    <div className={styles.mainLayout}>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default MainLayout;
