import classNames from 'classnames';
import { Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../components/Button/Button';
import DateSelector from '../../components/DateSelector/DateSelector';
import FaIcon from '../../components/FaIcon';
import Input from '../../components/Input/Input';
import MainLayout from '../../components/MainLayout/MainLayout';
import ActionConfirmation from '../../components/Modals/ActionConfirmation/ActionConfirmation';
import OrderHeader from '../../components/OrderHeader/OrderHeader';
import Table from '../../components/Table/Table';
import { requestReturnItems } from '../../core/api/products';
import { FaIcons } from '../../core/constants';
import { Product, ReturnRequestItem } from '../../core/types/product';
import useClickOutside from '../../hooks/useClickOutside';
import styles from './styles.module.scss';

type ItemForReturn = {
  articleNumber: number;
  description: string;
  quantity: number;
  weight: number;
  lotNumber: number;
  bestBefore: string;
  returnReason: string;
  imagePath: string;
};

type DateRowRef = {
  articleNr: number;
  dateInput: HTMLInputElement;
};

const EMPTY_ROW: ItemForReturn = {
  articleNumber: 0,
  description: '',
  quantity: 0,
  weight: 0,
  lotNumber: 0,
  bestBefore: moment().format('DD/MM/YYYY'),
  returnReason: '',
  imagePath: ''
};

const CELL_STYLES = {
  padding: '8px 4px !important'
};

const INNIT_DATE_SELECT_PROPS = {
  show: false,
  x: 0,
  y: 0,
  screenWidth: window.outerWidth
};

const ItemsReturnPage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'items-return' });
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [dateSelectProps, setDateSelectProps] = useState(
    INNIT_DATE_SELECT_PROPS
  );
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [submittedItems, setSubmittedItems] = useState<ItemForReturn[]>([]);
  const rowToRemoveRef = useRef<number>(0);
  const formRef = useRef<FormikProps<{ items: ItemForReturn[] }> | null>(null);
  const dateSelectRef = useRef<DateRowRef | null>(null);
  const dateSelectWrapperRef = useClickOutside(() => {
    if (dateSelectProps.show) {
      setDateSelectProps(INNIT_DATE_SELECT_PROPS);
    }
  });

  const handleSubmitReturn = async (items: ItemForReturn[]) => {
    try {
      const itemsForReturn = items.reduce(
        (result, item) => [
          ...result,
          {
            productArticleNumber: item.articleNumber.toString(),
            quantity: item.quantity,
            weight: item.weight,
            lotNumber: item.lotNumber.toString(),
            bestBefore: item.bestBefore,
            returnReason: item.returnReason,
            imagePath: item.imagePath
          }
        ],
        [] as ReturnRequestItem[]
      );

      await requestReturnItems({ itemsForReturn });

      formRef?.current?.resetForm();
      setSubmittedItems(items);
      setIsSubmitted(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFieldChange = ({
    fieldName,
    value,
    articleNr
  }: {
    fieldName:
      | 'quantity'
      | 'weight'
      | 'lotNumber'
      | 'bestBefore'
      | 'returnReason'
      | 'imagePath';
    value: number | string;
    articleNr: number;
  }) => {
    const items = formRef?.current?.values?.items;

    if (items?.length) {
      const currentItemIndex = items.findIndex(
        ({ articleNumber }) => articleNumber === articleNr
      );

      if (currentItemIndex !== -1) {
        const newItem = {
          ...items[currentItemIndex],
          [fieldName]: value
        };

        items.splice(currentItemIndex, 1, newItem);
        formRef.current?.setFieldValue('items', [...items]);
      }
    }
  };

  const requestRemove = (articleNr: number) => {
    setShowConfirmationModal(true);
    rowToRemoveRef.current = articleNr;
  };

  const handleItemRemove = () => {
    const articleNr = rowToRemoveRef.current;
    const items = formRef?.current?.values?.items;

    if (items?.length) {
      const newItems = items.filter(
        ({ articleNumber }) => articleNumber !== articleNr
      );

      formRef.current?.setFieldValue('items', [...newItems]);
    }
  };

  const imageChangeHandle = ({
    articleNr,
    file
  }: {
    articleNr: number;
    file: File | undefined;
  }) => {
    if (file) {
      handleFieldChange({
        articleNr,
        fieldName: 'imagePath',
        value: URL.createObjectURL(file)
      });
    }
  };

  const columns = [
    {
      name: t('table.article-number'),
      selector: (row: ItemForReturn) => row.articleNumber,
      grow: 0.5
    },
    {
      name: t('table.description'),
      selector: (row: ItemForReturn) => row.description,
      grow: 3,
      style: CELL_STYLES
    },
    {
      name: t('table.quantity'),
      cell: (row: ItemForReturn) =>
        isSubmitted ? (
          row.quantity
        ) : (
          <Input
            inputClassName={styles.tableInput}
            value={row.quantity}
            name="quantity"
            type="number"
            onChange={(e) =>
              handleFieldChange({
                articleNr: row.articleNumber,
                fieldName: 'quantity',
                value: e.target.value
              })
            }
          />
        ),
      style: CELL_STYLES
    },
    {
      name: t('table.weight'),
      cell: (row: ItemForReturn) =>
        isSubmitted ? (
          row.weight
        ) : (
          <Input
            inputClassName={styles.tableInput}
            value={row.weight}
            name="weight"
            type="number"
            onChange={(e) =>
              handleFieldChange({
                articleNr: row.articleNumber,
                fieldName: 'weight',
                value: e.target.value
              })
            }
          />
        ),
      style: CELL_STYLES
    },
    {
      name: t('table.lot-number'),
      cell: (row: ItemForReturn) =>
        isSubmitted ? (
          row.lotNumber
        ) : (
          <Input
            inputClassName={styles.tableInput}
            value={row.lotNumber}
            name="lotNumber"
            type="number"
            onChange={(e) =>
              handleFieldChange({
                articleNr: row.articleNumber,
                fieldName: 'lotNumber',
                value: e.target.value
              })
            }
          />
        ),
      style: CELL_STYLES
    },
    {
      name: t('table.best-before'),
      cell: (row: ItemForReturn) =>
        isSubmitted ? (
          row.bestBefore
        ) : (
          <div className={styles.dateInputRow}>
            <Input
              inputClassName={styles.tableInput}
              value={row.bestBefore}
              name="bestBefore"
              onClick={(e) => {
                e.stopPropagation();
                dateSelectRef.current = {
                  articleNr: row.articleNumber,
                  dateInput: e.currentTarget
                };
                setDateSelectProps({
                  show: true,
                  x: e.clientX,
                  y: e.clientY,
                  screenWidth: window.outerWidth
                });
              }}
            />
          </div>
        ),
      style: CELL_STYLES
    },
    {
      name: t('table.return-reason'),
      cell: (row: ItemForReturn) =>
        isSubmitted ? (
          row.returnReason
        ) : (
          <Input
            wrapperClassName={styles.returnReasonWrapper}
            inputClassName={styles.tableInput}
            value={row.returnReason}
            name="returnReason"
            onChange={(e) =>
              handleFieldChange({
                articleNr: row.articleNumber,
                fieldName: 'returnReason',
                value: e.target.value
              })
            }
          />
        ),
      grow: 3,
      style: CELL_STYLES
    },
    {
      name: t('table.image'),
      cell: (row: ItemForReturn) =>
        isSubmitted ? (
          <img src={row.imagePath || 'images/img-placeholder.svg'} />
        ) : (
          <>
            <label
              className={styles.fileInputLabel}
              htmlFor={`${row.articleNumber}-image-input`}
            >
              {row.imagePath ? (
                <img src={row.imagePath} />
              ) : (
                <FaIcon faName={FaIcons.cameraIcon} />
              )}
            </label>
            <input
              type="file"
              id={`${row.articleNumber}-image-input`}
              className={styles.fileInput}
              onChange={(e) => {
                imageChangeHandle({
                  articleNr: row.articleNumber,
                  file: e?.target?.files?.[0]
                });
              }}
            />
          </>
        ),
      grow: 1,
      style: CELL_STYLES
    },
    {
      name: '',
      cell: (row: ItemForReturn) => (
        <Button
          view="transparent"
          className={styles.removeButton}
          onClick={() => requestRemove(row.articleNumber)}
        >
          <FaIcon faName={FaIcons.trashCanIcon} />
        </Button>
      ),
      grow: 0.5,
      style: CELL_STYLES
    }
  ];

  const selectProduct = (product: Product) => {
    const newItem: ItemForReturn = {
      ...EMPTY_ROW,
      articleNumber: product.articleNr,
      description: product.description
    };
    const firstItem = formRef?.current?.values?.items?.[0];
    const isEmpty = !firstItem?.articleNumber;
    formRef.current?.setFieldValue('items', [
      ...(isEmpty ? [] : formRef.current.values.items),
      newItem
    ]);
  };

  const dateSelectX = dateSelectProps.x - 55;
  const dateSelectY = dateSelectProps.y - 33;

  const isSmallScreen = 350 + dateSelectProps.x > dateSelectProps.screenWidth;
  const dateSelectPosX = isSmallScreen ? { right: 5 } : { left: dateSelectX };

  return (
    <MainLayout>
      <div className={styles.itemsReturnPage}>
        <OrderHeader
          title={t('title')}
          backButtonTitle={t('back-btn-title')}
          searchPlaceholder={t('search-placeholder')}
          selectItem={selectProduct}
          showSearch
        />
        {isSubmitted && (
          <div className={styles.submitMessage}>
            <FaIcon faName={FaIcons.thumbUpIcon} />
            <span>{t('return-submit-message')}</span>
          </div>
        )}
        <Formik
          initialValues={{ items: [EMPTY_ROW] as ItemForReturn[] }}
          onSubmit={(values) => handleSubmitReturn(values.items)}
          innerRef={(form) => (formRef.current = form)}
        >
          {({ values: { items } }) => (
            <Form className={classNames(styles.returnForm)}>
              <section className={styles.returnFormContent}>
                {!!items.length && (
                  <Table<ItemForReturn>
                    columns={columns}
                    data={isSubmitted ? submittedItems : items}
                  />
                )}
              </section>

              {!isSubmitted && (
                <Button
                  type="submit"
                  view="green"
                  className={styles.submitButton}
                  disabled={!formRef.current?.values?.items?.length}
                >
                  <FaIcon faName={FaIcons.paperPlaneIcon} />{' '}
                  {t('submit-btn-title')}
                </Button>
              )}
            </Form>
          )}
        </Formik>
      </div>
      {showConfirmationModal && (
        <ActionConfirmation
          confirmationText={t('remove-confirmation-text')}
          confirmText={t('confirmation-yes')}
          cancelText={t('confirmation-no')}
          onClose={() => setShowConfirmationModal(false)}
          handleCancel={() => setShowConfirmationModal(false)}
          handleConfirm={handleItemRemove}
        />
      )}
      {dateSelectProps.show && (
        <div
          ref={dateSelectWrapperRef}
          className={styles.dateSelectWrapper}
          style={{
            top: dateSelectY,
            ...dateSelectPosX
          }}
        >
          <DateSelector
            className={styles.rowDateSelect}
            handleChange={(date) => {
              handleFieldChange({
                articleNr: dateSelectRef.current?.articleNr || 0,
                fieldName: 'bestBefore',
                value: moment(date).format('DD/MM/yyyy')
              });
              setDateSelectProps(INNIT_DATE_SELECT_PROPS);
            }}
          />
        </div>
      )}
    </MainLayout>
  );
};

export default ItemsReturnPage;
