import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import DeliveryDateSelect from '../DeliveryDateSelect/DeliveryDateSelect';
import MultiUserSelect from '../MultiUserSelect/MultiUserSelect';
import ShoppingBasketButton from '../ShoppingBasketButton/ShoppingBasketButton';
import styles from './styles.module.scss';

const AssortmentTopBar = () => {
  const [, setPrevScrollY] = useState<number>(
    document.documentElement.scrollTop
  );
  const disableEventRef = useRef<boolean>(false);
  const wrapperRef = useRef<HTMLElement | null>(null);

  const updatePrevScrollY = (prevValue: number) => {
    const newValue = window.scrollY;
    if (!disableEventRef.current) {
      if (prevValue > newValue) {
        if (!wrapperRef.current?.classList.contains(styles.show)) {
          wrapperRef.current?.classList.add(styles.show);
          disableEventRef.current = true;
        }
      } else if (prevValue < newValue) {
        if (wrapperRef.current?.classList.contains(styles.show)) {
          wrapperRef.current?.classList.remove(styles.show);
          disableEventRef.current = true;
        }
      }
    }

    return newValue || 0;
  };

  useEffect(() => {
    window?.addEventListener('scroll', () =>
      setPrevScrollY((prevValue) => updatePrevScrollY(prevValue))
    );

    return () =>
      window?.removeEventListener('scroll', () =>
        setPrevScrollY((prevValue) => updatePrevScrollY(prevValue))
      );
  }, []);

  return (
    <>
      <section
        ref={wrapperRef}
        onTransitionEnd={() => {
          disableEventRef.current = false;
        }}
        className={classNames(styles.assortmentTopBarWrapper, styles.show)}
      >
        <div className={classNames(styles.assortmentTopBar)}>
          <MultiUserSelect view="light" />
          <DeliveryDateSelect view="light" />
          <ShoppingBasketButton />
        </div>
      </section>
    </>
  );
};

export default AssortmentTopBar;
