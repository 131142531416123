import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';

import AuthLayout from '../../components/AuthLayout/AuthLayout';
import Button from '../../components/Button/Button';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import Input from '../../components/Input/Input';
import ValidationErrorMessage from '../../components/ValidationErrorMessage/ValidationErrorMessage';
import { authUser } from '../../core/api/user';
import { FaIcons, ROUTES } from '../../core/constants';
import styles from './styles.module.scss';

const LOGIN_FORM_INIT_VALUES = {
  email: '',
  password: ''
};

const LoginPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'login' });
  const { t: vT } = useTranslation('validation');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<string>('');

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .required(vT('required', { field: vT('fields.email') })),
    password: Yup.string().required(
      vT('required', { field: vT('fields.password') })
    )
  });

  const handleSubmit = async ({
    email,
    password
  }: {
    email: string;
    password: string;
  }) => {
    try {
      await authUser({ email, password });
      navigate('/assortment');
    } catch (error: any) {
      console.error(error);
      setLoginError(error.message);
    }
  };

  const passwordInputType = showPassword ? 'text' : 'password';
  const passwordIcon = showPassword ? FaIcons.eyeIcon : FaIcons.slashEyeIcon;

  return (
    <AuthLayout>
      <Formik
        onSubmit={handleSubmit}
        initialValues={LOGIN_FORM_INIT_VALUES}
        validationSchema={validationSchema}
        validateOnChange={false}
      >
        {({ values, handleChange, errors }) => {
          const errorMessage = errors.email || errors.password;
          return (
            <Form className={styles.loginForm}>
              <h2 className={styles.loginTitle}>{t('title')}</h2>
              <ErrorMessage message={loginError} />
              <ValidationErrorMessage message={errorMessage} />
              <div className={styles.formFields}>
                <Input
                  name="email"
                  type="email"
                  label={t('email-input-label')}
                  value={values.email}
                  onChange={handleChange}
                />
                <Input
                  name="password"
                  type={passwordInputType}
                  label={t('password-input-label')}
                  value={values.password}
                  onChange={handleChange}
                  Icon={passwordIcon}
                  iconAction={() => setShowPassword(!showPassword)}
                />
              </div>
              <div className={styles.formControls}>
                <Button
                  view="primary"
                  buttonText={t('submit-btn-title')}
                  type="submit"
                />
                <a href={ROUTES.forgetPassword} className={styles.passwordLink}>
                  {t('forgot-password')}
                </a>
              </div>
            </Form>
          );
        }}
      </Formik>
      <p className={styles.registerText}>
        <span>{t('dont-have-account')} </span>
        <a href="/" className={styles.registerLink}>
          {t('dont-have-account-link')}
        </a>
      </p>
    </AuthLayout>
  );
};

export default LoginPage;
