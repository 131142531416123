import { FaIcons } from '../../core/constants';
import FaIcon from '../FaIcon';
import styles from './styles.module.scss';

const WarningMessage = ({
  text,
  firstButtonText,
  firstButtonAction,
  secondButtonText,
  secondButtonAction
}: {
  text: string;
  firstButtonText?: string;
  firstButtonAction?: () => void;
  secondButtonText?: string;
  secondButtonAction?: () => void;
}) => {
  return (
    <div className={styles.warning}>
      <div className={styles.warningContent}>
        <p className={styles.warningText}>
          <FaIcon faName={FaIcons.warningIcon} />
          {text}
        </p>
        <div className={styles.warningButtons}>
          {!!firstButtonText && (
            <button
              className={styles.warningButton}
              onClick={() => firstButtonAction?.()}
            >
              {firstButtonText}
            </button>
          )}
          {!!secondButtonText && (
            <button
              className={styles.warningButton}
              onClick={() => secondButtonAction?.()}
            >
              {secondButtonText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default WarningMessage;
