import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

enum AvailableLocales {
  NL = 'nl',
  FR = 'fr',
  EN = 'en'
}

const LanguageToggle = ({
  dropdownView,
  light,
  className
}: {
  dropdownView?: boolean;
  light?: boolean;
  className?: string;
}) => {
  const { i18n } = useTranslation();
  const [activeLocale, setActiveLocale] = useState<AvailableLocales>(
    (i18n.language as AvailableLocales) || AvailableLocales.NL
  );
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const changeLanguage = (newLng: AvailableLocales) => {
    i18n.changeLanguage(newLng);
    setActiveLocale(newLng);
    setShowDropdown(false);
  };

  const handleToggleClick = (newLng: AvailableLocales) => {
    if (dropdownView || activeLocale === newLng) {
      setShowDropdown(!showDropdown);
    } else {
      changeLanguage(newLng);
    }
  };

  return (
    <div
      className={classNames(
        styles.languageToggleWrapper,
        {
          [styles.dropdownView]: dropdownView,
          [styles.light]: light
        },
        className
      )}
    >
      <button
        className={classNames(styles.languageToggleItem, {
          [styles.selected]: activeLocale === AvailableLocales.NL
        })}
        onClick={() => handleToggleClick(AvailableLocales.NL)}
      >
        NL
      </button>
      <button
        className={classNames(styles.languageToggleItem, {
          [styles.selected]: activeLocale === AvailableLocales.FR
        })}
        onClick={() => handleToggleClick(AvailableLocales.FR)}
      >
        FR
      </button>
      <button
        className={classNames(styles.languageToggleItem, {
          [styles.selected]: activeLocale === AvailableLocales.EN
        })}
        onClick={() => handleToggleClick(AvailableLocales.EN)}
      >
        EN
      </button>
      {showDropdown && (
        <div className={styles.toggleDropdown}>
          <button
            className={classNames(styles.dropdownButton, {
              [styles.selected]: activeLocale === AvailableLocales.NL
            })}
            onClick={() => changeLanguage(AvailableLocales.NL)}
          >
            Nederlands
          </button>
          <button
            className={classNames(styles.dropdownButton, {
              [styles.selected]: activeLocale === AvailableLocales.FR
            })}
            onClick={() => changeLanguage(AvailableLocales.FR)}
          >
            Français
          </button>
          <button
            className={classNames(styles.dropdownButton, {
              [styles.selected]: activeLocale === AvailableLocales.EN
            })}
            onClick={() => changeLanguage(AvailableLocales.EN)}
          >
            English
          </button>
        </div>
      )}
    </div>
  );
};

export default LanguageToggle;
