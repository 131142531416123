import { useEffect, useRef } from 'react';

const useClickOutside = (handleOutsideClick: () => void) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleClick = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node))
      handleOutsideClick();
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => document.removeEventListener('click', handleClick);
  });

  return ref;
};

export default useClickOutside;
