import { create } from 'zustand';

import { Cart } from '../core/types/product';

interface ShoppingCart {
  cart: Cart | null;
  discount: number;
  setDiscount: (discount: number) => void;
  setCart: (cart: Cart) => void;
}

const useCartStore = create<ShoppingCart>((set) => ({
  cart: null,
  discount: 0,
  setDiscount: (discount) => set({ discount }),
  setCart: (cart) => set({ cart })
}));

export default useCartStore;
