import classNames from 'classnames';
import { Calendar } from 'react-date-range';

import styles from './styles.module.scss';

const DateSelector = ({
  date = new Date(),
  handleChange,
  disabledDays = [],
  className
}: {
  date?: Date;
  handleChange: (date: Date) => void;
  disabledDays?: Date[];
  className?: string;
}) => {
  const getValidDate = (date: Date) => {
    if (disabledDays.includes(date))
      getValidDate(
        new Date(date.setMonth(date.getMonth(), date.getDate() + 1))
      );

    return date;
  };

  const getInitDate = () => {
    const currentDate = date || new Date();

    const validDate = getValidDate(currentDate);
    return validDate;
  };

  return (
    <div className={classNames(styles.dateSelector, className)}>
      <Calendar
        date={getInitDate()}
        classNames={{
          calendarWrapper: styles.calendarWrapper,
          monthAndYearWrapper: styles.monthAndYearWrapper,
          month: styles.month,
          monthPicker: styles.picker,
          yearPicker: styles.picker,
          dayHovered: styles.dayHovered,
          dayStartPreview: styles.dayStartPreview,
          selected: styles.selected,
          nextButton: styles.nextButton,
          prevButton: styles.prevButton,
          dayDisabled: styles.dayDisabled
        }}
        disabledDay={(date) => disabledDays?.includes(date)}
        onChange={handleChange}
      />
    </div>
  );
};

export default DateSelector;
