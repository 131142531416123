import classNames from 'classnames';

import { FaIcons } from '../../core/constants';
import FaIcon from '../FaIcon';
import styles from './styles.module.scss';

const CarouselArrow = ({
  direction,
  onClick
}: {
  direction: 'left' | 'right';
  onClick?: (e: any) => void;
}) => (
  <button
    className={classNames(styles.carouselArrow, styles[direction])}
    onClick={onClick}
  >
    <FaIcon
      faName={direction === 'left' ? FaIcons.chevronLeft : FaIcons.chevronRight}
      className={styles.chevronIcon}
    />
  </button>
);

export default CarouselArrow;
