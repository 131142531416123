import classNames from 'classnames';
import { ComponentProps } from 'react';

import { FaIcons } from '../../core/constants';
import FaIcon from '../FaIcon';
import Table from '../Table/Table';
import styles from './styles.module.scss';

interface IProps<T> {
  titleView: 'green' | 'orange';
  titleMainText: string;
  titleSubText?: string;
  titleIcon?: FaIcons;
  tableProps: ComponentProps<typeof Table<T>>;
  titleAction?: () => void;
}

const OrderTable = <T,>({
  titleView,
  titleMainText,
  titleSubText = '',
  titleIcon,
  tableProps,
  titleAction
}: IProps<T>) => {
  return (
    <section className={styles.orderContentWrapper}>
      <div className={styles.orderContent}>
        <button
          onClick={() => titleAction?.()}
          className={classNames(styles.titleButton, styles[titleView])}
        >
          <span>{titleMainText}</span>
          <span>
            {!!titleIcon && <FaIcon faName={titleIcon} />} {titleSubText}
          </span>
        </button>
        <div>
          <Table<T> {...tableProps} />
        </div>
      </div>
    </section>
  );
};

export default OrderTable;
