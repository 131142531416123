import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { FaIcons, ROUTES } from '../../core/constants';
import useCartStore from '../../store/shoppingCart';
import Counter from '../Counter/Counter';
import DropdownBlock from '../DropdownBlock/DropdownBlock';

const ShoppingBasketButton = () => {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  const { cart } = useCartStore();
  const itemsInCartCount = cart?.cartItems?.length || 0;

  const counterOptions: ComponentProps<typeof Counter> | undefined =
    itemsInCartCount
      ? {
          count: itemsInCartCount,
          view: 'red'
        }
      : undefined;

  return (
    <DropdownBlock
      view="green"
      title={t('articles-in')}
      value={t('shopping-basket')}
      iconName={FaIcons.basketShopping}
      counterOptions={counterOptions}
      onClick={() => navigate(ROUTES.shoppingBasket)}
    />
  );
};

export default ShoppingBasketButton;
