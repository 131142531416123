import Button from '../../Button/Button';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import styles from './styles.module.scss';

const ActionConfirmation = ({
  confirmationText,
  confirmText,
  cancelText,
  onClose,
  handleConfirm,
  handleCancel
}: {
  confirmationText: string;
  confirmText: string;
  cancelText: string;
  onClose: () => void;
  handleCancel: () => void;
  handleConfirm: () => void;
}) => {
  const cancel = () => {
    handleCancel();
    onClose();
  };

  const confirm = () => {
    handleConfirm();
    onClose();
  };

  return (
    <ModalWrapper onClose={cancel} bgColor="transparent">
      <div className={styles.confirmationModal}>
        <p className={styles.confirmationText}>{confirmationText}</p>
        <div className={styles.confirmationButtons}>
          <Button view="secondary" buttonText={confirmText} onClick={confirm} />
          <Button view="primary" buttonText={cancelText} onClick={cancel} />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ActionConfirmation;
