import classNames from 'classnames';
import { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FaIcons, LIST_VIEWS } from '../../core/constants';
import { Product } from '../../core/types/product';
import useCartStore from '../../store/shoppingCart';
import FaIcon from '../FaIcon';
import ProductDetailsModal from '../Modals/ProductDetails/ProductDetails';
import PriceSection from '../PriceSection/PriceSection';
import ProductLabels from '../ProductLabels/ProductLabels';
import styles from './styles.module.scss';

const ProductCard = ({
  view,
  product,
  isBasket,
  onClickAction
}: {
  view: LIST_VIEWS | 'promoted' | 'searched';
  product: Product;
  isBasket?: boolean;
  onClickAction?: () => void;
}) => {
  const { t } = useTranslation('translation');
  const { discount, setDiscount } = useCartStore();
  const [showProductDetail, setShowProductDetail] = useState<boolean>(false);
  const isPromoted = view === 'promoted';
  const promotionText = product?.promotion;
  const showPromotion = isPromoted && !!promotionText;

  const handleOnClick = () => {
    if (onClickAction) {
      onClickAction();
    } else {
      setShowProductDetail(true);
    }
  };

  const image = product?.images[0]?.thumbnail;

  return (
    <>
      <div
        onClick={handleOnClick}
        className={classNames(styles.productCard, styles[view], {
          [styles.grid]: view === 'promoted',
          [styles.table]: view === 'searched'
        })}
      >
        <div className={styles.productHead}>
          <img
            alt="product image"
            className={styles.productImage}
            src={image || 'images/img-placeholder.svg'}
          />
          <ProductLabels
            product={product}
            className={styles.gridLabels}
            view="column"
          />
          <button className={styles.favButton}>
            <FaIcon faName={FaIcons.emptyHeartIcon} />
          </button>
          {showPromotion && (
            <p className={styles.promotionText}>{promotionText}</p>
          )}
        </div>
        <div className={styles.productDescription}>
          <div className={styles.productTitle}>
            <p className={styles.productName}>{product.name}</p>
            <p className={styles.productSubtitle}>
              {product.itemsInPackage} x {product.weight}
              <ProductLabels
                product={product}
                className={styles.productLabels}
                view="row"
              />
            </p>
          </div>
          <p className={styles.productDetails}>
            <button className={classNames(styles.favButton)}>
              <FaIcon faName={FaIcons.emptyHeartIcon} />
            </button>
            <span className={styles.productDetail}>{product.articleNr}</span>
            <span className={styles.productDetail}>{product.weight}</span>
            <span className={styles.productDetail}>{product.points} PTS</span>
          </p>
        </div>
        <div
          className={styles.discountBlock}
          onClick={(event: SyntheticEvent) => event.stopPropagation()}
        >
          <label htmlFor="discount" className={styles.discountLabel}>
            {`${t('discount')} %`}
          </label>
          <input
            name="discount"
            type="number"
            className={styles.discountInput}
            value={discount}
            onChange={(e) => setDiscount(Number(e.target.value))}
            min="0"
            max="100"
          />
        </div>
        <PriceSection
          product={product}
          className={styles.cardPriceSection}
          view={isBasket ? 'in-basket' : 'default'}
        />
      </div>
      {showProductDetail && (
        <ProductDetailsModal
          product={product}
          onClose={() => setShowProductDetail(false)}
        />
      )}
    </>
  );
};

export default ProductCard;
