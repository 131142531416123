import { create } from 'zustand';

import { ASSORTMENT_ARTICLES, SortOptions } from '../core/constants';
import { ArticleItem } from '../core/types/product';

type ProductFilters = {
  search?: string;
  categories?: number[];
  article?: ArticleItem;
};

interface ProductsStore {
  currentPage: number;
  pageLimit: number;
  totalCount: number;
  isLoading: boolean;
  changePage: (page: number) => void;
  changeTotalCount: (count: number) => void;
  filters: {
    search: string;
    categories: number[];
    article: ArticleItem;
  };
  changeFilters: (changedFilters: ProductFilters) => void;
  sorting: SortOptions;
  changeSorting: (sortOption: SortOptions) => void;
}

const useProductsStore = create<ProductsStore>((set) => ({
  currentPage: 1,
  pageLimit: 8,
  totalCount: 0,
  isLoading: false,
  changePage: (page: number) => set({ currentPage: page }),
  changeTotalCount: (count: number) => set({ totalCount: count }),
  toggleLoading: () => set((state) => ({ isLoading: !state.isLoading })),
  filters: {
    search: '',
    categories: [],
    article: ASSORTMENT_ARTICLES[0]
  },
  changeFilters: (changedFilters: ProductFilters) =>
    set((state) => ({
      ...state,
      currentPage: 1,
      filters: { ...state.filters, ...changedFilters }
    })),
  sorting: SortOptions.productsInStock,
  changeSorting: (sorting) => set({ sorting })
}));

export default useProductsStore;
