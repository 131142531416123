import i18n from '../../i18n.config';
import { ERRORS } from '../constants';
import { Notification } from '../types/user';
import apiClient from './client';

type NotificationsResponse = {
  unreadCount: number;
  notifications: Notification[];
};

export const getNotifications = async (
  page: number
): Promise<Notification[] | Error> => {
  const { statusCode, response } = await apiClient.get<NotificationsResponse>(
    `api/notifications?page=${page}`
  );

  if (statusCode === 200) {
    return response.notifications;
  }

  throw new Error(i18n.t(ERRORS.default));
};

export const markAsRead = async (
  notificationIds: number[]
): Promise<void | Error> => {
  const { statusCode } = await apiClient.post('api/notifications/mark-read', {
    body: JSON.stringify({ notificationIds })
  });

  if (statusCode === 200) return;

  throw new Error(i18n.t(ERRORS.default));
};

export const markAsUnread = async (
  notificationIds: number[]
): Promise<void | Error> => {
  const { statusCode } = await apiClient.post('api/notifications/mark-unread', {
    body: JSON.stringify({ notificationIds })
  });

  if (statusCode === 200) return;

  throw new Error(i18n.t(ERRORS.default));
};

export const removeNotifications = async (
  notificationIds: number[]
): Promise<void | Error> => {
  const { statusCode } = await apiClient.post('api/notifications/delete', {
    body: JSON.stringify({ notificationIds })
  });

  if (statusCode === 200) return;

  throw new Error(i18n.t(ERRORS.default));
};
