import classNames from 'classnames';
import { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { FaIcons } from '../../../core/constants';
import FaIcon from '../../FaIcon';
import styles from './styles.module.scss';

const ModalWrapper = ({
  onClose,
  children,
  bgColor = 'white'
}: {
  onClose: () => void;
  children: ReactNode;
  bgColor?: 'white' | 'grey' | 'transparent';
}) => {
  const { t } = useTranslation('translation');

  return createPortal(
    <div className={classNames(styles.modalWrapper, styles[bgColor])}>
      <div className={styles.modalContent}>
        <button className={styles.mobileClose} onClick={onClose}>
          <FaIcon faName={FaIcons.arrowLeftLongIcon} />
          <span className={styles.modalCloseText}>{t('back')}</span>
        </button>
        <button className={styles.modalClose} onClick={onClose}>
          <span className={styles.modalCloseText}>{t('close')}</span>
          <FaIcon faName={FaIcons.xMark} className={styles.closeIcon} />
        </button>
        {children}
      </div>
    </div>,
    document.body
  );
};

export default ModalWrapper;
