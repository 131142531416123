import './styles.css';

import DataTable, {
  ConditionalStyles,
  TableColumn
} from 'react-data-table-component';

interface IProps<T> {
  columns: TableColumn<T>[];
  data: T[];
  conditionalRowStyles?: ConditionalStyles<T>[];
}

const Table = <T,>({ columns, data, conditionalRowStyles }: IProps<T>) => {
  return (
    <DataTable
      columns={columns}
      data={data}
      conditionalRowStyles={conditionalRowStyles}
    />
  );
};

export default Table;
