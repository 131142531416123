import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import MainLayout from '../../components/MainLayout/MainLayout';
import OrderInfo from '../../components/OrderInfo/OrderInfo';
import OrderTable from '../../components/OrderTable/OrderTable';
import { getOrder } from '../../core/api/order';
import { FaIcons, getOrderOverviewColumns, ROUTES } from '../../core/constants';
import { ItemInOrder, Order } from '../../core/types/product';
import styles from './styles.module.scss';

const OrderPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState<Order | null>(null);
  const [points, setPoints] = useState<number>(0);

  const fetchOrder = async () => {
    try {
      const orderData = await getOrder(Number(id));

      setOrder(orderData as Order);

      const orderPoints = (orderData as Order).items.reduce(
        (result, item) => result + item.points * item.count,
        0
      );
      setPoints(orderPoints);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchOrder();
    }
  }, [id]);

  const formattedDeliveryDate = moment(order?.deliveryDate).format(
    'DD/MM/yyyy'
  );

  return (
    <MainLayout>
      <div className={styles.orderPage}>
        <OrderInfo
          title={t('order-overview.title')}
          backButtonText={t('order-overview.back-btn-title')}
          backButtonAction={() => navigate(ROUTES.assortment)}
          orderNumber={order?.orderNumber}
          subTitle={
            <>
              {t('order-overview.sub-title')} <b>{order?.customerEmail}</b>
            </>
          }
          points={points}
        />
        <OrderTable<ItemInOrder>
          titleView="green"
          titleMainText={`${t('order-overview.ordered-on')} ${formattedDeliveryDate}`}
          titleSubText="PDF"
          titleIcon={FaIcons.printIcon}
          tableProps={{
            columns: getOrderOverviewColumns(t),
            data: order?.items as ItemInOrder[]
          }}
        />
      </div>
    </MainLayout>
  );
};

export default OrderPage;
