import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyState from '../../components/EmptyState/EmtyState';
import MainLayout from '../../components/MainLayout/MainLayout';
import OrderHeader from '../../components/OrderHeader/OrderHeader';
import OutstandingReturnTable from '../../components/OutstandingReturnTable/OutstandingReturnTable';
import { getRequestedReturns } from '../../core/api/products';
import { ReturnRequestStatus } from '../../core/constants';
import { RequestedReturn } from '../../core/types/product';
import styles from './styles.module.scss';

const OutstandingReturnsPage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'items-return' });
  const [requestedReturns, setRequestedReturns] = useState<RequestedReturn[]>(
    []
  );

  const fetchReturns = async () => {
    try {
      const fetchedRequests = await getRequestedReturns(
        ReturnRequestStatus.pending
      );

      setRequestedReturns(fetchedRequests as RequestedReturn[]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchReturns();
  }, []);

  const returnOrderDate = moment().format('DD/MM/YYYY');
  return (
    <MainLayout>
      <div className={styles.outstandingReturnsPage}>
        <OrderHeader
          title={t('requested-returns-title')}
          backButtonTitle={t('back-btn-title')}
        />
        {requestedReturns.length ? (
          <section className={styles.items}>
            {requestedReturns.map((requestedReturn) => (
              <OutstandingReturnTable
                key={requestedReturn.id}
                items={requestedReturn.items}
                returnDate={`${t('return-date')} ${returnOrderDate}`}
              />
            ))}
          </section>
        ) : (
          <EmptyState text={t('returns-empty-state-text')} />
        )}
      </div>
    </MainLayout>
  );
};

export default OutstandingReturnsPage;
