import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import EmptyState from '../../components/EmptyState/EmtyState';
import FaIcon from '../../components/FaIcon';
import MainLayout from '../../components/MainLayout/MainLayout';
import OrderInfo from '../../components/OrderInfo/OrderInfo';
import ProductCard from '../../components/ProductCard/ProductCard';
import { submitCart } from '../../core/api/cart';
import { FaIcons, LIST_VIEWS, ROUTES } from '../../core/constants';
import useCartStore from '../../store/shoppingCart';
import styles from './styles.module.scss';

const ShoppingBasketPage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'basket' });
  const { cart } = useCartStore();
  const navigate = useNavigate();
  const points = useMemo(() => {
    const cartPoints =
      cart?.cartItems?.reduce((result, item) => {
        const itemPoints = item.count * item.product.points;
        return itemPoints + result;
      }, 0) || 0;

    return cartPoints;
  }, [cart]);

  const submitOrder = async (comment: string) => {
    try {
      const orderId = await submitCart({ comment });
      navigate(`${ROUTES.orderOverview}/${orderId}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <MainLayout>
      <div className={styles.shoppingBasket}>
        <OrderInfo
          title={t('title')}
          backButtonText={t('back-btn-title')}
          backButtonAction={() => navigate(ROUTES.assortment)}
          titleIcon={FaIcons.basketShopping}
          points={points}
          // warningText={t('long-delivery-message')}
        />
        <section className={styles.contentWrapper}>
          <div className={styles.basketContent}>
            {cart?.cartItems?.length ? (
              <>
                {cart?.cartItems?.map(({ product }) => (
                  <ProductCard
                    view={LIST_VIEWS.table}
                    product={product}
                    key={product.id}
                    isBasket
                  />
                ))}
                <Formik
                  initialValues={{
                    comment: ''
                  }}
                  onSubmit={(values, actions) => {
                    submitOrder(values.comment);
                    actions.resetForm();
                  }}
                >
                  {({ values, handleChange }) => (
                    <Form className={styles.submitBlock}>
                      <div className={styles.blockHead}>
                        <label
                          className={styles.commentLabel}
                          htmlFor="comment"
                        >
                          <FaIcon faName={FaIcons.penToSquareIcon} />
                          {t('comment-field-label')}
                        </label>
                      </div>
                      <div className={styles.submitField}>
                        <textarea
                          id="comment"
                          name="comment"
                          className={styles.commentField}
                          value={values.comment}
                          onChange={handleChange}
                        />
                        <button className={styles.submitButton}>
                          {' '}
                          <FaIcon faName={FaIcons.paperPlaneIcon} />
                          <span>{t('submit-btn-title')}</span>
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </>
            ) : (
              <EmptyState text={t('empty-state-text')} />
            )}
          </div>
        </section>
      </div>
    </MainLayout>
  );
};

export default ShoppingBasketPage;
