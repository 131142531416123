import { TFunction } from 'i18next';

import { ItemInOrder } from './types/product';

export const STORAGE_KEYS = {
  auth_token: 'user_auth_token'
};

export const ROUTES = {
  login: '/login',
  forgetPassword: '/forget-password',
  resetPassword: '/reset-password',
  assortment: '/assortment',
  shoppingBasket: '/shopping-basket',
  orderOverview: '/order',
  itemsReturn: '/items-return',
  outstandingReturns: '/outstanding-returns',
  invoices: '/invoices',
  purchaseHistory: '/purchase-history',
  itemsOnOrder: '/items-on-order',
  notifications: '/notifications'
};

export const ERRORS = {
  default: 'default-error'
};

export enum LIST_VIEWS {
  grid = 'grid',
  table = 'table'
}

export enum FaIcons {
  eyeIcon = 'fa-regular fa-eye',
  slashEyeIcon = 'fa-regular fa-eye-slash',
  xMark = 'fa-solid fa-xmark',
  circleInfo = 'fa-solid fa-circle-info',
  userIcon = 'fa-light fa-user-large',
  messageIcon = 'fa-regular fa-message',
  fileArrowDownIcon = 'fa-regular fa-file-arrow-down',
  chevronDown = 'fa-solid fa-chevron-down',
  chevronLeft = 'fa-solid fa-chevron-left',
  chevronRight = 'fa-solid fa-chevron-right',
  headset = 'fa-light fa-headset',
  messagesQuestion = 'fa-light fa-messages-question',
  basketShopping = 'fa-regular fa-basket-shopping',
  calendar = 'fa-regular fa-calendar',
  list = 'fa-regular fa-list',
  arrowsRightLeft = 'fa-solid fa-arrow-right-arrow-left',
  emptyHeartIcon = 'fa-light fa-heart',
  filledHeartIcon = 'fa-solid fa-heart',
  truckIcon = 'fa-regular fa-truck',
  plusIcon = 'fa-solid fa-circle-plus',
  minusIcon = 'fa-solid fa-circle-minus',
  searchIcon = 'fa-solid fa-magnifying-glass',
  squireEmptyIcon = 'fa-thin fa-square',
  squireCheckedIcon = 'fa-solid fa-square-check',
  gridIcon = 'fa-solid fa-grid',
  starIcon = 'fa-regular fa-star',
  filledStarIcon = 'fa-solid fa-star',
  boltIcon = 'fa-solid fa-bolt',
  snowflakeIcon = 'fa-light fa-snowflake',
  forkKnifeIcon = 'fa-solid fa-fork-knife',
  tagIcon = 'fa-regular fa-tag',
  barsFilterIcon = 'fa-regular fa-bars-filter',
  barsIcon = 'fa-solid fa-bars',
  filterListIcon = 'fa-regular fa-filter-list',
  percentIcon = 'fa-solid fa-percent',
  bellIcon = 'fa-solid fa-bell',
  facebookIcon = 'fa-brands fa-facebook',
  instagramIcon = 'fa-brands fa-instagram',
  linkedInIcon = 'fa-brands fa-linkedin',
  circleArrowDownIcon = 'fa-sharp fa-light fa-circle-arrow-down',
  arrowLeftLongIcon = 'fa-light fa-arrow-left-long',
  warningIcon = 'fa-regular fa-diamond-exclamation',
  paperPlaneIcon = 'fa-sharp fa-light fa-paper-plane-top',
  penToSquareIcon = 'fa-light fa-pen-to-square',
  trashCanIcon = 'fa-light fa-trash-can',
  printIcon = 'fa-light fa-print',
  cameraIcon = 'fa-solid fa-camera',
  messageSmileIcon = 'fa-regular fa-message-smile',
  checkIcon = 'fa-regular fa-square-check',
  squareIcon = 'fa-regular fa-square',
  wineGlassIcon = 'fa-sharp fa-regular fa-wine-glass',
  thumbUpIcon = 'fa-regular fa-thumbs-up'
}

export enum ProductLabelsViews {
  yellow = 'yellow',
  red = 'red',
  blue = 'blue',
  grey = 'grey',
  darkGolden = 'dark-golden',
  yellowGreen = 'yellow-green'
}

export enum ProductLabelTypes {
  promo = 'promo',
  new = 'new',
  freezer = 'freezer',
  classic = 'classic',
  gold = 'gold',
  private = 'private'
}

export enum SortOptions {
  bestProducts = 'best products',
  productsInStock = 'products in stock'
}

export enum ArticlesItems {
  fullRange,
  myOrderList,
  promotions,
  quickSale,
  favorite
}

export const ASSORTMENT_ARTICLES = [
  {
    title: 'filters.articles.my-order-list',
    value: ArticlesItems.myOrderList
  },
  {
    title: 'filters.articles.full-range',
    value: ArticlesItems.fullRange
  },
  {
    title: 'filters.articles.promotions',
    value: ArticlesItems.promotions
  },
  {
    title: 'filters.articles.quick-sale',
    value: ArticlesItems.quickSale
  },
  {
    title: 'filters.articles.favorites',
    value: ArticlesItems.favorite
  }
];

export const getOrderOverviewColumns = (t: TFunction) => {
  return [
    {
      name: t('tables.order-overview.article-number'),
      selector: (row: ItemInOrder) => row.articleNr
    },
    {
      name: t('tables.order-overview.description'),
      selector: (row: ItemInOrder) => row.productName
    },
    {
      name: t('tables.order-overview.points'),
      selector: (row: ItemInOrder) => row.points
    },
    {
      name: t('tables.order-overview.special-discount'),
      selector: (row: ItemInOrder) => row.discount + '%'
    },
    {
      name: t('tables.order-overview.promotion'),
      selector: (row: ItemInOrder) => row.promotion
    },
    {
      name: t('tables.order-overview.extra-info'),
      selector: (row: ItemInOrder) => row.extraInformation
    },
    {
      name: t('tables.order-overview.number'),
      selector: (row: ItemInOrder) => row.count
    }
  ];
};

export enum OrderStatus {
  active = 'active',
  completed = 'completed'
}

export enum ReturnRequestStatus {
  pending = 'Pending',
  approved = 'Approved',
  rejected = 'Rejected'
}
