import { FaIcons } from '../../core/constants';
import FaIcon from '../FaIcon';
import styles from './styles.module.scss';
import classNames from 'classnames';

const CheckBox = ({
  toggle,
  checked,
  title
}: {
  toggle: () => void;
  checked: boolean;
  title?: string;
}) => {
  return (
    <button
      onClick={toggle}
      className={classNames(styles.checkBox, { [styles.checked]: checked })}
    >
      {checked ? (
        <FaIcon faName={FaIcons.squireCheckedIcon} />
      ) : (
        <FaIcon faName={FaIcons.squireEmptyIcon} />
      )}
      {title && <span className={styles.title}>{title}</span>}
    </button>
  );
};

export default CheckBox;
