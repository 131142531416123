import classNames from 'classnames';

import { FaIcons } from '../../core/constants';
import FaIcon from '../FaIcon';
import styles from './Input.module.scss';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  inputClassName?: string;
  label?: string;
  iconAction?: () => void;
  Icon?: FaIcons;
  iconClassName?: string;
  wrapperClassName?: string;
}

const Input = ({
  type = 'text',
  label,
  name,
  iconAction,
  Icon,
  iconClassName,
  inputClassName,
  wrapperClassName,
  ...args
}: IProps) => {
  return (
    <div className={classNames(styles.inputWrapper, wrapperClassName)}>
      {!!label && (
        <label className={styles.inputLabel} htmlFor={name}>
          {label}
        </label>
      )}
      <div className={styles.fieldWrapper}>
        <input
          id={name}
          name={name}
          type={type}
          className={classNames(styles.input, inputClassName)}
          {...args}
        />
        {Icon && (
          <FaIcon
            faName={Icon}
            onClick={() => iconAction?.()}
            className={classNames(styles.inputIcon, iconClassName)}
          />
        )}
      </div>
    </div>
  );
};

export default Input;
