import { FaIcons } from '../../core/constants';
import FaIcon from '../FaIcon';
import styles from './styles.module.scss';

const InfoMessage = ({ message }: { message: string }) =>
  message ? (
    <p className={styles.infoMessage}>
      <FaIcon faName={FaIcons.circleInfo} className={styles.infoIcon} />
      {message}
    </p>
  ) : (
    <></>
  );

export default InfoMessage;
