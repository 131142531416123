import classNames from 'classnames';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FaIcons } from '../../core/constants';
import { NotificationItem } from '../../core/types/user';
import Button from '../Button/Button';
import FaIcon from '../FaIcon';
import styles from './styles.module.scss';

interface IProps {
  notification: NotificationItem;
  toggleSelect: () => void;
  handleRemove: () => void;
}

const CHAR_LIMIT = 300;

const NotificationCard = ({
  notification,
  toggleSelect,
  handleRemove
}: IProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'notifications' });
  const [showMore, setShowMore] = useState<boolean>(false);

  const fullText = notification.notification;
  const shortText = fullText.slice(0, CHAR_LIMIT) + '...';
  const isLongText = fullText.length > CHAR_LIMIT;
  const displayedText = !isLongText || showMore ? fullText : shortText;

  const formattedDate = moment(notification.notificationDate).format(
    'DD-MM-YYYY'
  );

  const selectIcon = notification.isSelected
    ? FaIcons.checkIcon
    : FaIcons.squareIcon;

  return (
    <div
      className={classNames(
        styles.notificationCard,
        {
          [styles.selected]: notification.isSelected,
          [styles.unread]: !notification.isRead
        },
        styles['type' + notification.notificationType]
      )}
    >
      <Button
        view="transparent"
        className={styles.selectButton}
        onClick={toggleSelect}
      >
        <FaIcon faName={selectIcon} />
      </Button>
      <div className={styles.content}>
        <p className={styles.date}>{formattedDate}</p>
        <h2 className={styles.title}>{notification.notificationType}</h2>
        <p className={styles.notificationText}>{displayedText}</p>
        {isLongText && (
          <Button
            view="transparent"
            buttonText={showMore ? t('show-less') : t('show-more')}
            className={styles.readMoreButton}
            onClick={() => setShowMore(!showMore)}
          />
        )}
      </div>
      <Button view="transparent" className={styles.removeButton}>
        <FaIcon faName={FaIcons.trashCanIcon} onClick={handleRemove} />
      </Button>
    </div>
  );
};

export default NotificationCard;
