import classNames from 'classnames';
import { ComponentProps, ReactNode, useState } from 'react';

import { FaIcons } from '../../core/constants';
import useClickOutside from '../../hooks/useClickOutside';
import Counter from '../Counter/Counter';
import FaIcon from '../FaIcon';
import styles from './styles.module.scss';

const DropdownBlock = ({
  iconName,
  title,
  value,
  view,
  children,
  onClick,
  counterOptions,
  contentClassName,
  wrapperClassName
}: {
  title: string;
  value: string;
  iconName?: FaIcons;
  view: 'light' | 'green' | 'lg-transparent';
  children?: ReactNode;
  onClick?: () => void;
  counterOptions?: ComponentProps<typeof Counter>;
  contentClassName?: string;
  wrapperClassName?: string;
}) => {
  const [showDropdownContent, setShowDropdownContent] =
    useState<boolean>(false);
  const wrapperRef = useClickOutside(() => setShowDropdownContent(false));

  const handleClick = () => {
    if (onClick) onClick();
    else setShowDropdownContent(!showDropdownContent);
  };
  return (
    <div
      className={classNames(styles.dropdownBlock, wrapperClassName)}
      ref={wrapperRef}
    >
      <section
        className={classNames(styles.dropdownHead, styles[view])}
        onClick={handleClick}
      >
        <p className={styles.headTitleWrapper}>
          <span className={styles.dropdownTitle}>{title}</span>
          <span className={styles.dropdownValue}>{value}</span>
        </p>
        {!!iconName && (
          <FaIcon faName={iconName} className={styles.dropdownIcon} />
        )}
        {counterOptions && (
          <Counter
            {...{
              ...counterOptions,
              className: classNames(
                styles.dropdownCounter,
                counterOptions.className
              )
            }}
          />
        )}
      </section>
      {!!children && showDropdownContent && (
        <section
          className={classNames(styles.dropdownContent, contentClassName)}
        >
          {children}
        </section>
      )}
    </div>
  );
};

export default DropdownBlock;
