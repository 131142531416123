import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { FaIcons, ProductLabelsViews } from '../../core/constants';
import FaIcon from '../FaIcon';
import styles from './styles.module.scss';

const ProductLabel = ({
  view,
  text,
  icon,
  className
}: {
  view: ProductLabelsViews;
  text: string;
  icon: FaIcons;
  className?: string;
}) => {
  const { t } = useTranslation('translation');

  return (
    <span className={classNames(styles.productLabel, styles[view], className)}>
      <FaIcon faName={icon} />
      {t(text)}
    </span>
  );
};

export default ProductLabel;
