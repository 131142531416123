import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import AuthLayout from '../../components/AuthLayout/AuthLayout';
import Button from '../../components/Button/Button';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import InfoMessage from '../../components/InfoMessage/InfoMessage';
import Input from '../../components/Input/Input';
import ValidationErrorMessage from '../../components/ValidationErrorMessage/ValidationErrorMessage';
import { forgetPassword } from '../../core/api/user';
import { ROUTES } from '../../core/constants';
import styles from './styles.module.scss';

type ForgetPasswordValues = {
  email: string;
};

const FORGET_PASSWORD_FORM_INIT_VALUES: ForgetPasswordValues = {
  email: ''
};

const ForgetPasswordPage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'forget-password' });
  const { t: vT } = useTranslation('validation');
  const [forgetPasswordError, setForgetPasswordError] = useState<string>('');
  const [isSucceeded, setIsSucceeded] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .required(vT('required', { field: vT('fields.email') }))
  });

  const handleSubmit = async (values: ForgetPasswordValues) => {
    try {
      await forgetPassword(values);

      setIsSucceeded(true);
    } catch (error: any) {
      setForgetPasswordError(error.message);
    }
  };

  return (
    <AuthLayout>
      <Formik
        onSubmit={handleSubmit}
        initialValues={FORGET_PASSWORD_FORM_INIT_VALUES}
        validationSchema={validationSchema}
        validateOnChange={false}
      >
        {({ values, handleChange, errors }) => (
          <Form className={styles.forgetPasswordForm}>
            <div className={styles.formContent}>
              <h2 className={styles.formTitle}>{t('title')}</h2>
              <ErrorMessage message={forgetPasswordError} />
              <ValidationErrorMessage message={errors.email} />
              {isSucceeded && <InfoMessage message={t('success-message')} />}
              <Input
                name="email"
                type="email"
                label={t('email-input-label')}
                value={values.email}
                onChange={handleChange}
              />
            </div>
            <Button
              view="primary"
              buttonText={t('submit-btn-title')}
              type="submit"
            />
            <a href={ROUTES.login} className={styles.backToLogin}>
              {t('back-to-login')}
            </a>
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default ForgetPasswordPage;
