import classNames from 'classnames';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { FaIcons } from '../../core/constants';
import Button from '../Button/Button';
import DeliveryDateSelect from '../DeliveryDateSelect/DeliveryDateSelect';
import DropdownBlock from '../DropdownBlock/DropdownBlock';
import FaIcon from '../FaIcon';
import MultiUserSelect from '../MultiUserSelect/MultiUserSelect';
import WarningMessage from '../WarningMessage/WarningMessage';
import styles from './styles.module.scss';

const OrderInfo = ({
  title,
  backButtonText,
  backButtonAction,
  titleIcon,
  warningText,
  orderNumber,
  subTitle,
  points
}: {
  title: string;
  backButtonText: string;
  backButtonAction: () => void;
  points: number;
  titleIcon?: FaIcons;
  warningText?: string;
  orderNumber?: string;
  subTitle?: ReactNode;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'basket' });

  return (
    <section className={styles.basketHead}>
      <div className={styles.basketHeadContent}>
        <div className={styles.basketTitleBlock}>
          <h1 className={styles.title}>
            {!!titleIcon && <FaIcon faName={titleIcon} />}
            <p>
              {title}
              {!!subTitle && <span>{subTitle}</span>}
            </p>
          </h1>
          <Button
            buttonText={backButtonText}
            view="secondary"
            onClick={backButtonAction}
            className={styles.backButton}
          />
        </div>
        <div className={styles.basketTopBar}>
          <div className={styles.topBarContent}>
            <div
              className={classNames(styles.topBarButtons, {
                [styles.withThirdItem]: orderNumber
              })}
            >
              <MultiUserSelect view="lg-transparent" />
              <DeliveryDateSelect view="lg-transparent" />
              {orderNumber && (
                <DropdownBlock
                  title={t('order-number')}
                  value={orderNumber}
                  view="lg-transparent"
                />
              )}
            </div>
            <p className={styles.pointsCount}>
              <span className={styles.count}>+ {points}</span>
              {t('points')}
            </p>
          </div>
          {!!warningText && (
            <WarningMessage
              text={warningText}
              firstButtonText={t('yes')}
              secondButtonText={t('no')}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default OrderInfo;
